@import 'mixins.scss';

.thinScroll {
  .ant-modal-body {
    overflow-y: auto;
    height: calc(100vh - 20rem);

    &::-webkit-scrollbar {
      margin-top: -8px !important;
      width: 8px;
      height: 8px;
      background: #fafafa;
      border: 1px solid #fafafa;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3.5px;
      background-color: #ced4da;

      &:hover {
        background-color: #adb5bd;
      }
    }

    &::-webkit-scrollbar-track {
      background: #fafafa;
    }
  }
}

.tagBtn {
  transition: background-color .3s;
}

.tagBtn:hover {
  background-color: #CDDEFF;
}

.disabledRow {
  background-color: #dcdcdc;
  pointer-events: none;
}