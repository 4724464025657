
.row-dragging {
  z-index: 1000;
}

.center-block {
  display:flex;
  align-items: center;
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}


//code-list-item-option의 초기 세팅을 안보이게 설정.
.code-list-item-option {
  opacity: 0;
  transition: opacity 0.3s;
}

//code-list-item에 마우스를 올리변 code-list-item-option이 보임.
.code-list-item:hover .code-list-item-option {
  opacity: 1;
}

//code-list-item-option의 button이 disabled상태면 display: none
.code-list-item .code-list-item-option > button:disabled {
  display: none;
}

.code-list-item {
  background: #949898;
}