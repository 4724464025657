@import 'mixins.scss';

.pending {
  background: rgba(0,0,0,0.1);

  &:hover {
    background: rgba(0,0,0,0.1);
  }

  & .ant-table-cell-fix-right {
    background: rgba(0,0,0,0.1) !important;
  }
}

.selectRow {
  cursor: pointer;
}

.actionCell {
  cursor: auto;
}

.roleCheckbox {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 10%;
  transition: all 0.5s;
}

.roleCheckbox:checked {
  background: $blue-light;
}

.backslash {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="0" x2="100%" y2="100%" opacity="0.1" stroke="black" /></svg>');
  border-right: solid 1px rgba(0,0,0,0.1);
  width:140px !important;

  & div {
    text-align: right;
  }
}

.infoBackground {
  background: rgba(255,255,255,0.75);
  box-shadow: $shadow;
  padding: 2rem;
  min-height: 20rem;
}

.TBD {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 36px;
  color: #dadada;

}

.datePickerWrapper {
 & > div {
   width: 100%;
 }
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.headerSticky {
  position: sticky;
  background: white;
  top: 0;
}

.undefinedSiteRow {
  background: #FFCCCC;
}

.blockingArea:before {
  position: absolute;
  content: '';
  background-color: grey;
  opacity: 0.05;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:5;
}
