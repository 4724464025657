.blockingArea:before {
    position: absolute;
    content: '';
    background-color: grey;
    opacity: 0.05;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:5;
}

.blockingAreaFooter:before {
    position: absolute;
    content: '';
    background-color: grey;
    opacity: 0.05;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:11;
}