//@import "mixins.scss";

.wizardTable table { font-size: 10px; }

//.antTableCell {
//  padding:2px !important;
//}

.fontSize11px {
  font-size: 10px;
}
