@import 'mixins.scss';

.oid {
  background:#fafafa;
  color:purple;
  fontWeight:600;
  fontSize:0.5rem;
}

.itemName {
  fontSize:0.8rem;
}

.menu {
  padding:0px;
  background: #fdfdfd !important;
}

.search {
  padding: 0.5rem;
  margin: 0.5rem 0 0.5rem 0;
  border: dashed 1px #eee;
}

.externalCodeList {
  pointer-events: none;
}