@import 'mixins.scss';

.infoBackground {
  background: rgba(255,255,255,0.75);
  box-shadow: $shadow-5;
  padding: 2rem;
  min-height: 20rem;
}

.backslash {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="0" x2="100%" y2="100%" opacity="0.1" stroke="black" /></svg>');
  border-right: solid 1px rgba(0,0,0,0.1);
  width:140px !important;

  & div {
    text-align: right;
  }
}

.selectRow {
  cursor: pointer;
}

.actionCell {
  cursor: auto;
}


.actionCheckbox {
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 10%;
  transition: all 0.5s;
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}