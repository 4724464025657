.definitions {
    min-height:calc(100vh - 250px)
}

.selected-item {
    border:1px solid #814c97 !important;
}

.v-middle {
    vertical-align: middle !important;
}