.odm-modal {

  & .ant-modal-content {
    padding: 0px;
  }

  & .ant-modal-header {
    margin: 0 0 16px;
    padding: 20px 24px;
    background-color: #4b7cf3;
  }

  & .ant-modal-title {

    & span {
      font-family: NotoSansCJKkr;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
    }

    line-height: 1;
    letter-spacing: -0.5px;
    color: var(--white);

    & .ant-row {
      align-items: center;
    }
  }

  & .ant-modal-close-x{
    color : var(--white);
  }

  & .ant.modal-footer {
    padding: 2rem;
  }
}