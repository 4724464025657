@import 'mixins.scss';

.crf-version-tabs, .management-tabs, .odm-tab, .settings-tabs {
  margin-left: rem(20);
  & > .nav-item {
    background: #fafafa;
    margin-right: rem(3);
    font-size: 13px !important;

    & > .nav-link > a {
      display:flex;
      align-items: center;
      justify-content: center;
    }

    & .active{
      //background: $primary !important;
      background: white !important;
    }

    & .active {
      color: $primary !important;
    }
  }
}

.content {
  background: white;
}
