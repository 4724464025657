@import 'mixins.scss';

.itemWrapper {
  display: flex;
  width: 100%;
}

.itemTitleBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.questionBlock {
  max-width:300px !important;
  //overflow:hidden !important;
  //text-overflow:ellipsis !important;
  //white-space:nowrap !important;
  font-size:0.9rem;
  height: 16px;

  & pre {
    white-space: pre-wrap;
  }
}

.checkboxContent {
  display: inline-flex;
  max-width:300px !important;
  word-break: break-all !important;
  word-wrap: break-word !important;
  font-size:0.9rem;

  & pre {
    white-space: pre-wrap;
  }
}

.valueListTextBlock {
  display: inline-block;
  max-width:180px !important;
  overflow:hidden !important;
  text-overflow:ellipsis !important;
  white-space:nowrap !important;
  font-size:0.9rem;
}

.repeatingItemTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
}

.repeatingItemTitle {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}

.descriptionRepeating {
  display: inline-block !important;
  max-width: 180px !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btnShadow {
  box-shadow: $shadow-2 !important;
}

.cursorPointer {
  cursor: pointer;
}