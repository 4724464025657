/*@import "~react-datepicker/dist/react-datepicker.min.css";*/

:root {
  --kit-color-primary: #4b7cf3;
  --kit-font-family: 'Mukta', sans-serif;
}

body {
  margin: 0;
  font-family: 'Mukta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1200px !important;
}

body [class^="ant-layout"], body [class*=" ant-layout"] {
  font-family: 'Mukta', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
}

::-webkit-scrollbar {
   width: 10px;
   height: 8px;
   background: #ffffff;
 }

::-webkit-scrollbar-thumb {
  border-radius: 3.5px;
  background-color: #ced4da;
}

::-webkit-scrollbar-track {
   background: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*form input, textarea, select {*/
/*  background-color: #f8f6f8 !important;*/
/*}*/

.MuiInputBase-root, .MuiInputLabel-root, MuiInputBase-fullWidth {
  background-color: #f8f6f8 !important;
}

.stop-dragging
{
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

#container {
  min-width: 1200px !important;
}

#aside-container {
  z-index: 998 !important;
}
#navbar {
  z-index: 999 !important;
}

#mainnav-container {
  z-index: 1000 !important;
}

div[role="tooltip"] {
  z-index:9999 !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}


/*.ant-table table { font-size: 10px; }*/

/*.ant-table-cell {*/
/*  padding:2px !important;*/
/*  !*margin:0px;*!*/
/*}*/




  /** ECS React-ace Editor */
.ace_editor {
  /*border:1px solid #d9d9d9;*/
  background: #fafafa;
}
.ace_editor.ace_autocomplete {
  width:600px;
}

.ace_editor
.ace_cjk {
  font-family: "dotum", "Malgun Gothic", Tahoma, Verdana;
  font-size:12px;
}
/*.ace_gutter-layer {*/
/*  background: #eeeeee;*/
/*}*/
/*.ace_gutter-cell {*/
/*  font-family: Consolas, Verdana, Tahoma;*/
/*  font-size:12px;*/
/*}*/
/*.ace_storage {*/
/*  color:darkorange;*/
/*}*/
/*.ace_marker-layer > .ecs_error {*/
/*  !*background: rgba(245, 16, 16, 0.75) !important;*!*/
/*  border-bottom: 1px dashed red;*/
/*  border-radius: 0;*/
/*  !*z-index: 999;*!*/
/*  position: absolute !important;*/
/*  !*text-decoration: underline;*!*/
/*}*/
.ecs_error {
  /*top: 2px !important;*/
  position: absolute !important;
  border-bottom: 2px dotted red;
}
.ace_identifier {
  color: #0000ff;
  font-weight: 500;
  /*font-style: italic;*/
}

.ace_lparen, .ace_rparen {
  color:darkorange;
}

.ace_operator {
  color: #605e5e;
  font-weight: 600;
}

.ace_keyword {
  font-weight: 600;
}

.ace_constant {
  color: #333333 !important;
  font-weight: 600;
}
/**----------------------------------------*/
/*.ace_identifier {*/
/*}*/
/*.ecs-warning {*/
/*  position: absolute !important;*/
/*  width:8px !important;*/
/*  color:red !important;*/
  /*border-bottom:2px dotted red !important;*/
  /*margin-left:-8px !important;*/
  /*z-index: 999;*/
  /*background: red !important;*/
/*}*/

/*.ecs-warning:after {*/
/*  position: absolute;*/
/*  z-index: 9999;*/
/*  z-index: 999999;*/
/*  content: '\25C0';*/
/*  font-size:20px;*/
/*  color:red !important;*/
/*  width:200px !important;*/
/*}*/
/** ECS React-ace Editor */

.old {
  display:none;
}

.compare-changed {
  color:red;
}

.compare-changed > .old {
  display:inline-block;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fafafa;
}

/*ECS Editor rule error message*/
.ant-form-item-explain-error > div[role='alert'] {
  font-size:12px;
}

.sae-querybuilder-combinators {
  width:60px !important;
}
.sae-querybuilder-field {
  width:200px !important;
}
.sae-querybuilder-control {
  width:120px !important;
}
.sae-querybuilder-value {
  width:250px !important;
}

.site-collapse-custom-collapse {
  border: 1px solid #d9d9d9 !important;
  /*background-color: #fafafa !important;*/
}
.site-collapse-custom-collapse .site-collapse-custom-panel {
  /*margin-bottom: 24px;*/
  /*overflow: hidden;*/
  /*border: 1px solid #d9d9d9 !important;*/
  background: #fafafa;

  /*border-radius: 2px;*/
}
