@import 'mixins.scss';

.noContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.protocol {
  user-select: none;


  & .protocolHeader {
    //display: flex;
    //flex-direction: row;
    //justify-content: flex-start;
    //width: 100%;
    //min-height: 100px;
    text-align: center;
    vertical-align: middle;
    position: sticky;
    top: 0;
    background: white;
    color: #595c97;


    z-index: 100;

    //color: white;
    font-weight: bold;

    & .backslash {
      //display: flex;
      //flex-direction: column;
      //justify-content: space-evenly;
      border: solid 1px #e4e9f0;
      position: sticky;
      left: 0;
      //border-top-left-radius: 15px;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" style="background-color:white"><line x1="0" y1="0" x2="100%" y2="100%" opacity="1" stroke="rgb(228, 233, 240)" /></svg>');
      min-width: 140px;
    }

    & .studyEventTitle {
      min-width: 99px;
    }

    & .studyEventTitle:last-child {
      border-right: solid 0px;
    }

    & .studyEventTitleBlock {
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
      max-width: 99px;
    }

  }

  & .protocolBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 1rem;

    & .formWrapper {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-self: flex-start;
      min-width: 100%;

      &:hover {
        background: #f8f8f8;
      }
    }


    & .formTitle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: solid 1px #e4e9f0;
      border-top: 0px;
      position: sticky;
      left: 0;
      background: white;
      min-width: 140px;
      min-height: 80px;
      color: #595c97;
      font-size: 12px;
      font-weight: bold;
      width:120px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
    }

    & .formContentBlock {
      display: flex;
      flex-direction: row;
      flex: 1;
    }

    & .formContentGroup {
      display:flex;
      flex-direction: row;
      flex: 1;
    }

    & .formContent {
      display: flex;
      flex: 1 1 100%;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      min-width: 100px;
      border: solid #e4e9f0;
      border-width: 0px 1px 1px 0px;
    }

    & .formNoContent {
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      min-width: 100px;
      border: solid #e4e9f0;
      border-width: 0px 1px 1px 0px;
    }

  }
}


.stickyBottom {
  position: sticky;
  bottom: 0;
  background: white;
}


.ellipsisSpan {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.blockingArea:before {
  position: absolute;
  content: '';
  background-color: grey;
  opacity: 0.05;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:5;
}

.blockingAreaFooter:before {
  position: absolute;
  content: '';
  background-color: grey;
  opacity: 0.05;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:11;
}