@import 'mixins.scss';

.stickyDiv {
  position:sticky;
  position: -webkit-sticky;
  top:0px;
  margin:5px;
  z-index: 99;

  & .card-header {
    background-color: #fff !important;
  }
}

.formBuilderSider {
  max-width: 450px !important;
  min-width: 450px !important;
  width: 450px !important;
  padding:0 10px 5px 10px !important;
  margin:0px !important;
  background-color: #ffffff;
  border:2px solid #eeeeee;

  & .ant-tabs-tab {
    margin:0 5px 0 0 !important;
  }
}


.itemWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
